import baseService from './baseService'

export const downloadFileService = {
    downloadReport,
    downloadFINReport,
    downloadExcel,
    downloadSavedExcel,
    downloadResource,
    downloadCustomerReport,
    downloadAccessToken
}

function downloadReport(data) {
    return baseService.fileDownloadPostRequest('/api/data/pdf/lender', data)
}
function downloadFINReport(data) {
    return baseService.fileDownloadPostRequest('/api/data/pdf/lender2', data)
}
function downloadExcel(data) {
    return baseService.fileDownloadPostRequest('/api/secure/export/excel', data)
}

function downloadSavedExcel(data) {
    return baseService.fileDownloadPostRequest('/api/secure/export/downloadExcel', data)
}

function downloadResource(data) {
    return baseService.fileDownloadPostRequest('/api/data/file/learningResources', data)
}

function downloadCustomerReport(data) {
    return baseService.fileDownloadPostRequest('/api/data/pdf/customer', data)
}

function downloadAccessToken(data) {
    return baseService.fileDownloadPostRequest('/api/data/pdf/token', data)
}
