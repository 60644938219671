import baseService from './baseService'

export const userService = {
  login,
  forgotPassword,
  confirmPassword
}

function login (username, password) {
  return baseService.postRequest403Unhandled('api/auth/authenticate', { username: username, password: password })
}

function forgotPassword (request) {
  return baseService.postRequest('/api/preLogin/forgotPassword', request)
}

function confirmPassword (request) {
  return baseService.postRequest('/api/preLogin/confirmPassword', request)
}