export const en = {
    'save': 'Save',
    'cancel': 'Cancel',
    'confirm': 'Confirm',
    'yes': 'Yes',
    'reset': 'Reset',
    'filter': 'Filter',
    'ok': 'OK',
    'login': 'Login',
    'logout': 'Logout',
    'logout_now': 'Log Out Now',
    'continue': 'Continue',
    'years': 'Years',
    'months': 'Months',

    icon: {
        'SME': 'mdi-factory',
        'MIKRO': 'mdi-account-group'
    },
    msg: {

        'successfully_changedPassword': 'You have successfully changed password. Please log in using your username and password.',
        'brNumber_error_required': 'Please enter BR Number',
        'brNumber_error_min': 'Business registration (BR) / business license number must be at least 5 characters long.',
        'brNumber_error_max': 'Business registration (BR) / business license number must not be longer than 20 characters.',
        'brNumber_error_regex': 'Only alphabet, numerals, and the following special characters: - and / are accepted.',
        'fullName': 'Full Name',
        'officerName_error_required': 'Please enter officer name',
        'officerId_error_required': 'Please enter officer id',

        'business_category_error_required': 'Please select business category',
        'gender_error_required': 'Please select gender',
        'ethnicity_error_required': 'Please select gender',

        'applicationNumber_error_required': 'Please enter customer\'s application number.',
        'applicationNumber_error_max': 'Maximum length should be less than 10 digits.',
        'applicationNumber_error_numeric': 'Only numbers are allowed.',
        'applicationNumber_error_alpha_num': 'Only alphabets and numbers are accepted.',
        'applicationNumber_error_0': 'Application Number already exists',
        'applicationNumber_error_regex': 'Only alphabets and numbers are accepted.',




        'fullName_error_required': 'Please enter customer\'s full name.',
        'fullName_error_min': 'Full Name must be at least 5 characters long.',
        'fullName_error_regex': 'Only letters of the alphabet, spaces, and the following special characters: @ ’ - and / are accepted.',
        'fullName_error_max': 'Full Name cannot be longer than 80 characters.',

        'position_error_min': 'Minimum length should be at least 2 characters.',
        'position_error_max': 'Maximum length cannot be longer than 100 characters.',
        'position_error_required': 'Please enter customer\'s position.',
        'position_error_regex': 'Only alphabet, spaces, numerals, and the following special characters: . & - () , / % and \' are accepted.',

        'companyName_error_required': 'Please enter customer\'s company name.',
        'companyName_error_min': 'Company name must be at least 5 characters long.',
        'companyName_error_regex': 'Only alphabet, spaces, numerals, and the following special characters: . - & () and ’ are accepted.',
        'companyName_error_max': 'Company name must not be longer than 80 characters.',

        'annualSales_error_required': 'Please enter customer\'s  annual sales.',
        'annualSales_error_numeric': 'Only numbers are allowed.',
        'annualSales_error_max_value': 'The maximum entry is 999999999.',
        'annualSales_error_min_value': 'The minimum entry is 0.',

        'annualProfit_error_required': 'Please enter customer\'s  annual profit.',
        'annualProfit_error_numeric': 'Only numbers are allowed.',
        'annualProfit_error_max_value': 'Annual profit cannot be greater than annual sales.',
        'annualProfit_error_min_value': 'The minimum entry is 0.',

        'financingAmount_error_required': 'Please enter the loan amount.',
        'financingAmount_error_max_value': 'The maximum entry is {0} {2}.',
        'financingAmount_error_min_value': 'The minimum entry is {0} {1}.',
        'financingAmount_error_numeric': 'Only numbers are allowed.',

        'tenureApplied_error_required': 'Please enter the loan tenure.',
        'tenureApplied_error_max_value': 'The maximum entry is {1}.',
        'tenureApplied_error_min_value': 'The minimum entry is {0}.',
        'tenureApplied_error_numeric': 'Only numbers are allowed.',

        'financingAmountApproved_error_required': 'Please enter the loan amount approved.',
        'financingAmountApproved_error_numeric': 'Only numbers are allowed.',
        'financingAmountApproved_error_min_value': 'The minimum entry is {0} {1}.',
        'financingAmountApproved_error_max_value': 'The maximum entry is {0} {2}.',

        'tenureApproved_error_numeric': 'Only numbers are allowed.',
        'tenureApproved_error_required': 'Please enter the loan tenure approved.',
        'yearsApproved_error_required': 'Please enter Years.',
        'monthsApproved_error_required': 'Please enter Months.',
        'tenureApproved_error_max_value': 'The maximum entry is {1}.',
        'tenureApproved_error_min_value': 'The minimum entry is {0}.',

        'employeesCount_error_numeric': 'Only numeric values are accepted',
        'employeesCount_error_min_value': 'The number of full-time employees must be at least 1.',
        'employeesCount_error_max_value': 'The number of full-time employees must not exceed 10000.',

        'facility_error_required': 'Please enter facility',
        'facility':'Facility',
        'facility_error_max_value': 'The maximum entry is 999999999.',
        'facility_error_min_value': 'The minimum entry is 0.',
        'facility_error_numeric': 'Only numbers are allowed.',

        'employeesCount_error_required': 'Please enter the number of employee',
        'officerId_error_numeric': 'Only numbers are allowed.',
        'officerId_error_min': 'The ID must be at least 4 characters long.',
        'officerId_error_max': 'The ID number cannot be longer than 6 characters.',

        'officerName_error_regex': 'Only alphabet, spaces, and the following special characters: @ ’ - and / are accepted',
        'officerName_error_min': 'The name must be at least 5 characters long.',
        'officerName_error_max': 'The name cannot be longer than 80 characters.',

        'ctosRating_error_required': 'Please enter CTOS score of the customer.',
        'ctosRating_error_max': 'Cannot be more than 100 characters',
        'ctosRating_error_numeric': 'Numeric values only',
        'ctosRating_error_min_value': 'The minimum value should be greater than 300',
        'ctosRating_error_max_value': 'The maximum value should not be greater than 850',

        'ccrisRating_error_required': 'Please enter CCRIS score of the customer.',
        'ccrisRating_error_numeric': 'Numeric values only',
        'ccrisRating_error_min_value': 'The minimum value should be greater than 0',
        'ccrisRating_error_max_value': 'The maximum value should not be greater than 999',

        'internalRating_error_required': 'Please enter internal credit rating of the customer.',
        'internalRating_error_min': 'The rating must be at least 1 characters long.',
        'internalRating_error_max': 'The rating cannot be longer than 3 characters.',
        'internalRating_error_regex': 'The only acceptable characters are letters of the alphabet, numeral, space and the following special characters: + - # and @',

        'businessType_error_required': 'Please enter business type of the customer',
        'businessType_error_min': 'Business Type must be at least 3 characters long.',
        'businessType_error_max': 'Business Type must not be longer than 150 characters.',
        'businessType_error_regex': 'Only alphabet, spaces, numerals, and the following special characters: / % , . - & () and ’ are accepted.',


        'idNumber_error_required': 'Please enter your NRIC/passport number',
        'idNumber_error_max': 'NRIC/passport number must not be longer than 12 characters.',
        'idNumber_error_regex': 'Only letters of the alphabet and numerals are accepted.',

        'idNumberType_error_required': 'Please select your idNumberType',

        // Login
        'username_error_required': 'Please enter your username',
        'username_error_min': 'The ID number must be at least 6 characters long.',
        'username_error_max': 'The ID number must not be longer than 15 characters.',
        'username_error_regex': 'The only acceptable characters are letters of the alphabet, numeral and the following special characters: ! # - and @',

        'password_error_required': 'Please enter your password',

        'email_address_error_required': 'Please enter the email address.',
        'email_address_error_min': 'The ID number must be at least 1 characters long.',
        'email_address_error_max': 'The ID number must not be longer than 20 characters.',
        'email_address_error_regex': ' Invalid email address',

        'confirmPassword_error_required': 'Please confirm your password',
        'otp_error_required': 'Please enter your OTP',
        'otp_error_max':"The OTP must be atleast 6 characters long",
        'otp_error_min':"The OTP must not be longer than 6 characters.",
        'otp_error_regex':"Invalid OTP",
        'password': 'Password',
        'confirmPassword': 'Confirm Password',

        'serverError': 'Unknown Error has occurred. Please contact support.',
        'general_serverError': 'Unknown Error has occurred. Please contact support.',
        'username_used': 'The user name has been used. Please use a new one',
        'SMS_error_required': 'Please enter the number to which the credentials will be delivered.',
        'Email_error_required': 'Please enter the Email Address to which the credentials will be delivered.',
        'Email_error_email': 'Please enter the email in the correct format.',
        'product_error_required': 'Please choose the product',
        'general_repeating_user': 'This user exist and within the time frame.',
        'country_error_required': 'Please select country',

        // Dashboard
        'export_button': 'Export',
        'dashboard': 'Dashboard',
        'results': 'Results',
        'learning_resources': 'Learning Resources',

        // Headers
        'sl_no': 'No.',
        'verification_code': 'Verification Code',
        'customer_id':'Customer ID',
        'application_number': 'Application No.',
        'full_time_employees': 'Number of full-time employees',
        'gender': 'Gender',
        'br_number': 'Business Registration (BR) No.',
        'br_number_type': 'Business Registration (BR) No. Type',
        'company_name': 'Company Name',
        'id_number': 'Identification Number (ID)',
        'id_number_type': 'ID Document Type',
        'customer_name': 'Customer Name',
        'business_category': 'Business Category',
        'staff_name': 'EMK',
        'supervisor_name': 'Supervisor',
        'branch': 'Branch',
        'region': 'Region',
        'organisation': 'Organisation',
        'scheme': 'Product',
        'financing_amount_requested': 'Loan Amount (WS$)',
        'tenure_applied': 'Tenure',
        'assessment_type': 'Assessment Type',
        'gfi_status': 'Status',
        'gfi_registration_date': 'Registration Date',
        'gfi_completion_date': 'Completion Date',
        'update_details': 'Update Details',
        'gfi_scoring': 'EDP Scoring',
        'application_status': 'Application Status',
        'report': 'Report',
        'expiry_date': 'EDP Expiry Date',
        'f1': 'F1',
        'f2': 'F2',
        'f3': 'F3',
        'gfi_rating': 'EDP Rating',
        'credit_score': 'EDP Credit Score',
        'access_id': 'Access Id',
        'gfi_credit_score': 'EDP Credit Score',
        'gfi_credit_rating': 'EDP Credit Rating',
        'download_report': 'EDP Report',
        'download_ent_report': 'Entrepreneurial Competence Report',
        'download_financial_report': 'Financial Competence Report',

        // Dropdowns
        'category': 'Category',
        'category_label': 'Category',
        'scheme_label': 'Product',
        'amount_label': 'Loan Amount Range',
        'status_label': 'Status',
        'applicationStatus_label': 'Loan Application Status',
        'date_label': 'Date',
        'start_date': 'Start Date',
        'end_date': 'End Date',
        'branch_label': 'Branch',
        'branch_error_required':'Please enter the branch',
        'region_label': 'Region',
        'supervisor_label': 'Supervisor',
        'officer_label': 'EMK',
        'report_unavailable': 'Please wait while the report is being generated.<br>If the problem persists please contact support',
        'export_unavailable': 'We encountered an error while exporting data , please try again.<br>If the problem persists please contact support',
        'close_label': 'Close',
        'scheme_all_applied': 'All Products',
        'amount_all_applied': 'All Amount Ranges',
        'gfiStatus_all_applied': 'All Status',
        'rmOfficer_all_applied': 'All EMKs',
        'supervisor_all_applied': 'All Supervisors',
        'status_all_applied': 'All Status',
        'applicationStatus_all_applied': 'All Loan Application Status',
        'region_all_applied': 'All Regions',
        'branch_all_applied': 'All Branches',
        'officer_all_applied': 'All EMKs',

        'malaysianOwned_error_required': 'Select an option',
        'date_error_required': 'Select a date',
        'editedCommencementDate_error_required': 'Please select a date',
        'natureofbusiness_error_required': 'Select an option',

        // score generation dialog
        'position' : 'Position',

        // TimeOut Dialog
        'session_expire_heading': 'Session Expiration Warning',
        'session_expire_message_1': 'Your session will expire in',
        'session_expire_message_2': 'seconds. Please click “Continue” to continue your session or click “Log Out Now” to end your session now.',

        'export_history': 'Export History',
        'table_heading_export': 'Data Export History',
        'table_header_no': 'No.',
        'table_header_date': 'Export Date',
        'table_header_finance_type': 'Finance Type',
        'table_header_filter_applied': 'Filters Applied',
        'table_header_status': 'Status',
        'table_header_download': 'Download',

        // Update Details (Customer Maintenance)
        'score_generated_text_1': 'EDP Scores for this customer has been generated already. Please confirm you want to change the details for this customer. Please note that doing so will remove the current scores and new score will have to be generated.',
        'score_generated_text_2': 'Do you want to proceed ?',
        'edit_customer_info': 'Edit Customer Information',
        'failed_action': 'One or more customer information have errors, please provide correct information to save successfully.',
        'asterisk_remark': ' This field is required to be filled in order to generate EDP scores',

        // Application Status (Update Application Status)
        'update_application_details': 'Update Application Details',

        // GFI Scoring (Score Generation Confirmation Widget)
        'score_generation_text_1': 'Are you sure you want to generate EDP scores for this customer?',
        'score_generation_text_2': 'Please note that you will not be able to change the customer details after scores have been generated.',

        // Export History
        'ready': 'Ready',
        'processing': 'Processing',
        'filters_applied': 'Filters Applied',
        'programme': 'Product',
        'financing_amount_range': 'Loan Amount Range',
        'financing_application_status': 'Loan Application Status',
        'officer': 'EMK',
        'supervisor': 'Supervisor',
        'searched_by': 'Searched By',
        'all_programmes': 'All Products',
        'all_financing_amount_ranges': 'All Loan Amount Ranges',
        'all_gfi_status': 'All Status',
        'all_financing_application_status': 'All Loan Application Status',
        'all_regions': 'All Regions',
        'all_officers': 'All EMK\'s',
        'all_supervisors': 'All Supervisors',
        'all_branches': 'All Branches',
        'note_footer': 'Note: System displays only the last 5 attempts to export data',

        // Learning Resources
        'download_resources': 'Download Resources',
        'resource_name': 'Resource Name',
        'download': 'Download',

        // Profile
        'profile_title': 'Profile',
        'username': 'Username',
        'name': 'Name',
        'id': 'ID',
        'staff_id': 'Staff ID',
        'icon_text': '*Click on icon below profile picture to upload new profile picture',
        'avatar_size_warning': 'Avatar size should be less than 20 kb!',
        'img_size_warning': 'Image size should be less than 20 kb',

        // User List
        'filters_title': 'Filters',
        'customers': 'Customers',
        'view_applied_filters': 'View Applied Filters',
        'all_dates': 'All Dates',
        'f1_text': 'F1 : Compliance',
        'f2_text': 'F2 : Entrepreneurship',
        'f3_text': 'F3 : Financial Decision-Making',

        // Invalid Session & Unauthorized
        'invalid_text_1': 'Something is wrong here...',
        'invalid_text_2': 'You might have opened the dashboard in another tab/window.',
        'invalid_text_3': 'Please close this tab/window.',
        'invalid_expired_text': 'Your session has expired !!',
        'home': 'Home',
        'confirm_password_header':'Confirm Password',
        'forgot_password_header':'Forgot Password',
        'email_address':'Email Address',
        'forgot_password':'Forgot Password',
        'cancel':"Cancel",
        'otp':'OTP'
    },

    error: {
        'server': 'Something went wrong. Please try again later.',
        'score_generate_error_text_1': 'We were unable to generate EDP Credit Sore, please try again later.',
        'score_generate_error_text_2': 'If the problem persists, please contact support.',
        'information_tampering': 'Customer information cannot be same as another existing customer',
        'tenure_minvalue': 'The minimum loan tenure must be at least {0} months.',
        'tenure_maxvalue': 'The maximum loan tenure must not exceed {0} months.',
    },

    lbl: {
        'application_number': 'Application Number',
        'full_name': 'Full Name',
        'fullName': 'Full Name',
        'id_number': 'Identification Number (ID)',
        'id_number_type': 'ID Document Type',
        'gender': 'Gender',
        'product':'Product',
        'br_number_type': 'Business Registration (BR) No. Type',
        'position': 'Job Position',
        'business_reg_num': 'Business Registration Number',
        'company_name': 'Company Name',
        'branch': 'Branch',
        'business_category': 'Business Category',
        'business_category_required': 'Business Category',
        'product_applied': 'Product Applied',
        'financing_amount_applied': 'Loan Amount',
        'financing_tenure_applied': 'Loan Tenure',
        'financing_amount_approved': 'Loan Amount Approved',
        'financing_tenure_approved': 'Loan Tenure Approved',
        'application_status': 'Application Status',
        'tenure_years': 'Financing Tenure (in years)',
        'tenure_months': 'Financing Tenure (in months)',
        'tenure_approved_years': 'Tenure Approved (in years)',
        'tenure_approved_months': 'Tenure Approved (in months)',
        'customer_type': 'Customer Type',
        'application_Status': 'Application Status',
        'business_type': 'Type of Business',
        'business_size': 'Number of full time Employees',
        'business_location': 'Business Location',
        'customer_id':'Customer ID',
        'application_id':'Application Number',
        'facility':'Facility'
    },
}
